import React, { useState, useEffect } from "react";
import styles from "../ModifySearch/styles.module.css";

function PassengersClassPanel({
  adults,
  children,
  infants,
  travelClass,
  onPassengersChange,
  onTravelClassChange,
  show,
}) {
  const [localAdults, setLocalAdults] = useState(adults);
  const [localChildren, setLocalChildren] = useState(children);
  const [localInfants, setLocalInfants] = useState(infants);
  const [localTravelClass, setLocalTravelClass] = useState(travelClass);

  useEffect(() => {
    onPassengersChange(localAdults, localChildren, localInfants);
  }, [localAdults, localChildren, localInfants, onPassengersChange]);

  useEffect(() => {
    onTravelClassChange(localTravelClass);
  }, [localTravelClass, onTravelClassChange]);

  return (
    <div
      className={`${styles.passengersPanel} ${
        show ? styles.show : styles.hide
      }`}
    >
      <div className={styles.panelHeader}>
        <span>Passengers</span>
      </div>
      <div className={styles.passengerOptions}>
        <div className={styles.passengerOption}>
          <div className={styles.passengerInfo}>
            <span className={styles.passengerType}>Adults</span>
            <span className={styles.passengerAge}>12+ years</span>
          </div>
          <div className={styles.counter}>
            <button
              className={styles.counterButton}
              onClick={() => setLocalAdults(Math.max(1, localAdults - 1))}
            >
              -
            </button>
            <span className={styles.counterValue}>{localAdults}</span>
            <button
              className={styles.counterButton}
              onClick={() => setLocalAdults(localAdults + 1)}
            >
              +
            </button>
          </div>
        </div>
        <div className={styles.passengerOption}>
          <div className={styles.passengerInfo}>
            <span className={styles.passengerType}>Children</span>
            <span className={styles.passengerAge}>2-11 years</span>
          </div>
          <div className={styles.counter}>
            <button
              className={styles.counterButton}
              onClick={() => setLocalChildren(Math.max(0, localChildren - 1))}
            >
              -
            </button>
            <span className={styles.counterValue}>{localChildren}</span>
            <button
              className={styles.counterButton}
              onClick={() => setLocalChildren(localChildren + 1)}
            >
              +
            </button>
          </div>
        </div>
        <div className={styles.passengerOption}>
          <div className={styles.passengerInfo}>
            <span className={styles.passengerType}>Infants</span>
            <span className={styles.passengerAge}>Under 2 years</span>
          </div>
          <div className={styles.counter}>
            <button
              className={styles.counterButton}
              onClick={() => setLocalInfants(Math.max(0, localInfants - 1))}
            >
              -
            </button>
            <span className={styles.counterValue}>{localInfants}</span>
            <button
              className={styles.counterButton}
              onClick={() => setLocalInfants(localInfants + 1)}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className={styles.travelClass}>
        <span className={styles.travelClassTitle}>Class</span>
        <label className={styles.travelClassLabel}>
          <input
            type="radio"
            name="class"
            value="Economy"
            checked={localTravelClass === "Economy"}
            onChange={(e) => setLocalTravelClass(e.target.value)}
          />
          Economy
        </label>
        <label className={styles.travelClassLabel}>
          <input
            type="radio"
            name="class"
            value="Business"
            checked={localTravelClass === "Business"}
            onChange={(e) => setLocalTravelClass(e.target.value)}
          />
          Premium (Business/First)
        </label>
      </div>
    </div>
  );
}

export default PassengersClassPanel;
