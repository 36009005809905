import React from "react";
import styles from "./styles.module.css";

function SwitchButton({ tripType, onChange }) {
  const handleLeftClick = () => {
    onChange("oneway");
  };

  const handleRightClick = () => {
    onChange("return");
  };

  return (
    <div className={styles.buttonBox}>
      <div
        id={styles.btn}
        className={tripType === "return" ? styles.right : styles.left}
      ></div>
      <button
        type="button"
        className={styles.toggleBtn}
        onClick={handleLeftClick}
      >
        One way
      </button>
      <button
        type="button"
        className={styles.toggleBtn}
        onClick={handleRightClick}
      >
        Return
      </button>
    </div>
  );
}

export default SwitchButton;
