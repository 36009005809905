export function findMatchingOrClosestOfferDate(dateString, objects) {
    const targetDate = new Date(dateString);

    let matchingObject = null;
    let closestObject = null;
    let minDifference = Infinity;

    for (let obj of objects) {
        const objDate = new Date(obj.departureDate);

        // Check if the object has at least one offer
        if (obj.offers && obj.offers.length > 0) {
            // Check if the date matches the target date
            if (obj.departureDate === dateString) {
                matchingObject = obj;
                break;
            } else {
                // Calculate the difference in days between target date and current object's date
                const difference = Math.abs(targetDate - objDate);
                if (difference < minDifference) {
                    closestObject = obj;
                    minDifference = difference;
                }
            }
        }
    }

    // Return the matching object if found, else return the closest object
    return (matchingObject || closestObject || {departureDate: dateString}).departureDate;
}