// src/utils/getCityName.js
export const getCityName = (code) => {
  const cityMap = {
    BEY: "Beirut",
    LOS: "Lagos",
    KUL: "Kuala Lumpur",
    DOH: "Doha"
  };
  return cityMap[code] || code;
};
