import React from 'react';
import styles from './ComingSoon.module.css';

function ComingSoon() {
    return (
        <div className={styles.comingSoonContainer}>
            <div className={styles.content}>
                <h1 className={styles.title}>Coming Soon</h1>
                <p className={styles.message}>We&apos;re preparing for takeoff! Our new features are on the way.</p>
                <a href="/" className={styles.homeLink}>Return to Home</a>
            </div>
        </div>
    );
}

export default ComingSoon;