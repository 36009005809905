import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { getCityName } from "../../utils/getCityName";
import { DateTime } from "luxon";
import { removeParenthesesContent } from "../../utils/removeParenthesis.js";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ViewDetail({ show, onClose, flight }) {
  if (!flight || !flight.selectedOffer || !flight.selectedOffer.segments)
    return null;

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"; // Disable body scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable body scrolling
    }
    return () => {
      document.body.style.overflow = "auto"; // Clean up on component unmount
    };
  }, [show]);

  const calculateLayoverDuration = (arrivalTime, nextDepartureTime) => {
    const arrival = DateTime.fromISO(arrivalTime, { setZone: true });
    const departure = DateTime.fromISO(nextDepartureTime, { setZone: true });
    const durationInMinutes = Math.round((departure - arrival) / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return { hours, minutes };
  };

  const calculateTotalDuration = (segments) => {
    let totalFlightDuration = 0;
    let totalLayoverDuration = { hours: 0, minutes: 0 };

    for (let i = 0; i < segments.length; i++) {
      const departureTime = DateTime.fromISO(segments[i].departureTime, {
        setZone: true,
      });
      const arrivalTime = DateTime.fromISO(segments[i].arrivalTime, {
        setZone: true,
      });

      totalFlightDuration += arrivalTime.diff(departureTime, "minutes").minutes;

      if (i < segments.length - 1) {
        const nextDepartureTime = DateTime.fromISO(
          segments[i + 1].departureTime,
          { setZone: true },
        );
        const layover = calculateLayoverDuration(
          arrivalTime.toISO(),
          nextDepartureTime.toISO(),
        );
        totalLayoverDuration.hours += layover.hours;
        totalLayoverDuration.minutes += layover.minutes;
      }
    }

    totalLayoverDuration.hours += Math.floor(totalLayoverDuration.minutes / 60);
    totalLayoverDuration.minutes = totalLayoverDuration.minutes % 60;

    return {
      totalFlightDuration,
      totalLayoverDuration,
      totalDuration: {
        hours: Math.floor(
          (totalFlightDuration +
            totalLayoverDuration.hours * 60 +
            totalLayoverDuration.minutes) /
            60,
        ),
        minutes:
          (totalFlightDuration +
            totalLayoverDuration.hours * 60 +
            totalLayoverDuration.minutes) %
          60,
      },
    };
  };

  const { totalDuration } = calculateTotalDuration(
    flight.selectedOffer.segments,
  );

  const formatTimeWithDayInfo = (time) => {
    const dt = DateTime.fromISO(time, { setZone: true });
    const isNextDay =
      dt.toISODate() !==
      DateTime.fromISO(flight.selectedOffer.segments[0].departureTime, {
        setZone: true,
      }).toISODate();
    const formattedTime = dt.toLocaleString(DateTime.TIME_24_SIMPLE);
    return isNextDay ? `${formattedTime} <sup>+1</sup>` : formattedTime;
  };

  return (
    <>
      {show && <div className={styles.overlay} onClick={onClose} />}
      <div className={`${styles.panel} ${show ? styles.show : styles.hide}`}>
        <div className={styles.header}>
          <span>Flight Details</span>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={styles.scrollContainer}>
          <div className={styles.content}>
            {flight.selectedOffer.segments.map((segment, segmentIndex) => (
              <div className={styles.segmentCard} key={segmentIndex}>
                <h3>{`${removeParenthesesContent(getCityName(segment.from))} to ${removeParenthesesContent(getCityName(segment.to))}`}</h3>
                <p className={styles.date}>
                  {DateTime.fromISO(segment.departureTime, {
                    setZone: true,
                  }).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </p>
                <div className={styles.flightSegment}>
                  <div className={styles.timeLocation}>
                    <div className={styles.time}>
                      {DateTime.fromISO(segment.departureTime, {
                        setZone: true,
                      }).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </div>
                    <div className={styles.locationColumn}>
                      <span className={styles.location}>
                        {removeParenthesesContent(getCityName(segment.from))}
                      </span>
                      <span className={styles.airport}>
                        {segment.from} Airport
                      </span>
                    </div>
                  </div>
                  <div className={styles.flightDetails}>
                    <span className={styles.duration}>
                      {Math.floor(segment.durationInMinutes / 60)}h{" "}
                      {segment.durationInMinutes % 60}m
                    </span>
                    <div className={styles.airlineInfo}>
                      <img
                        src={segment.airlineLogo}
                        alt="Airline Logo"
                        className={styles.airlineLogo}
                      />
                      <div className={styles.aircraftInfo}>
                        <span className={styles.flightInfo}>
                          {segment.flightCode} - {segment.aircraft}
                        </span>
                        <span className={styles.operatedBy}>
                          Operated by {segment.airline}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.timeLocation}>
                    <div
                      className={styles.time}
                      dangerouslySetInnerHTML={{
                        __html: formatTimeWithDayInfo(segment.arrivalTime),
                      }}
                    />
                    <div className={styles.locationColumn}>
                      <span className={styles.location}>
                        {removeParenthesesContent(getCityName(segment.to))}
                      </span>
                      <span className={styles.airport}>
                        {segment.to} Airport
                      </span>
                    </div>
                  </div>
                  {segmentIndex < flight.selectedOffer.segments.length - 1 && (
                    <div className={styles.layover}>
                      Layover:{" "}
                      {
                        calculateLayoverDuration(
                          segment.arrivalTime,
                          flight.selectedOffer.segments[segmentIndex + 1]
                            .departureTime,
                        ).hours
                      }
                      h{" "}
                      {
                        calculateLayoverDuration(
                          segment.arrivalTime,
                          flight.selectedOffer.segments[segmentIndex + 1]
                            .departureTime,
                        ).minutes
                      }
                      m
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className={styles.totalDuration}>
              <h3>Total Trip Duration:</h3>
              <p>
                {totalDuration.hours}h {totalDuration.minutes}m
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewDetail;
