import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faExchangeAlt,
  faArrowRight,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ModifySearch from "../ModifySearch/ModifySearch";
import { FlightContext } from "../../context/FlightContext";
import { useSearchFlights } from "../../hooks/useSearchFlights";
import { findMatchingOrClosestOfferDate } from "../../utils/FindMatchingOrClosestOffer";
import queryString from "query-string";

function SearchBar() {
  const location = useLocation();
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [showPassengerTooltip, setShowPassengerTooltip] = useState(false);
  const {
    setFlights,
    setIsLoading,
    setIsError,
    searchCriteria,
    setSearchCriteria,
    setSelectedDate,
    setLastSearchId,
    setSelectedDepartureFlight,
    setSelectedReturnFlight,
  } = useContext(FlightContext);
  const { mutate } = useSearchFlights();
  const searchBarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (Object.keys(params).length > 0) {
      const {
        origin,
        destination,
        adults = 1,
        children = 0,
        infants = 0,
        departureDate,
        returnDate,
        travelClass = "Economy",
        tripType = "return",
      } = params;

      const parsedSearchCriteria = {
        origin: origin || searchCriteria.origin,
        destination: destination || searchCriteria.destination,
        adults: parseInt(adults),
        children: parseInt(children),
        infants: parseInt(infants),
        dates: [
          {
            date: departureDate || searchCriteria.dates[0].date,
            label: formatDateLabel(
              departureDate || searchCriteria.dates[0].date,
            ),
          },
          tripType === "return" && returnDate
            ? { date: returnDate, label: formatDateLabel(returnDate) }
            : null,
        ].filter(Boolean),
        tripType,
        travelClass,
      };

      setSearchCriteria(parsedSearchCriteria);
      handleSearchFlights(parsedSearchCriteria);
    }
  }, [location.search]);

  const formatDateLabel = (date) => {
    const options = { weekday: "short", day: "numeric", month: "short" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleSearchClick = () => {
    setShowModifySearch(true);
  };

  const handleClosePanel = () => {
    setShowModifySearch(false);
  };

  const updateSearchCriteria = (newSearchCriteria) => {
    setSearchCriteria(newSearchCriteria);
    handleSearchFlights(newSearchCriteria);
  };

  const handleSearchFlights = (formData) => {
    const payload = {
      adultsAmount: formData.adults,
      childrenAmount: formData.children,
      infantsAmount: formData.infants,
      daysFlexibility: 3,
      itinerary: [
        {
          from: formData.origin,
          to: formData.destination,
          departureDate: formData.dates[0]?.date,
        },
      ],
      cabinType: formData.travelClass
        ? formData.travelClass.toUpperCase()
        : "ECONOMY",
    };

    if (formData.dates.length > 1) {
      payload.itinerary.push({
        from: formData.destination,
        to: formData.origin,
        departureDate: formData.dates[1].date,
      });
    }

    navigate("/");
    setSelectedDepartureFlight(null);
    setSelectedReturnFlight(null);

    setIsLoading(true);
    setIsError(false);

    mutate(payload, {
      onSuccess: (data) => {
        setSelectedDate(
          findMatchingOrClosestOfferDate(
            formData.dates[0]?.date,
            data.results.filter((result) => result.itinerarySegment === 0),
          ),
        );
        setLastSearchId(data.searchId);
        setFlights(data.results);
        setIsLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching flights:", error);
        setSelectedDate(null);
        setLastSearchId(null);
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClosePanel();
    }
  };

  return (
    <div className={styles.searchBarContainer} ref={searchBarRef}>
      <div className={styles.searchBar}>
        <div
          className={`${styles.section} ${styles.location}`}
          onClick={handleSearchClick}
        >
          <span>{searchCriteria.origin}</span>
          <FontAwesomeIcon
            icon={
              searchCriteria.tripType === "oneway"
                ? faArrowRight
                : faExchangeAlt
            }
          />
          <span>{searchCriteria.destination}</span>
        </div>
        <div className={styles.divider}></div>
        <div
          className={`${styles.section} ${styles.passenger}`}
          onMouseEnter={() => setShowPassengerTooltip(true)}
          onMouseLeave={() => setShowPassengerTooltip(false)}
          onClick={handleSearchClick}
        >
          <FontAwesomeIcon icon={faUser} />
          <span>
            {searchCriteria.adults +
              searchCriteria.children +
              searchCriteria.infants}{" "}
            Passenger
            {searchCriteria.adults +
              searchCriteria.children +
              searchCriteria.infants !==
            1
              ? "s"
              : ""}
          </span>
          {showPassengerTooltip && (
            <div className={styles.tooltip}>
              <p>
                <strong>Adults:</strong> {searchCriteria.adults}
              </p>
              <p>
                <strong>Children:</strong> {searchCriteria.children}
              </p>
              <p>
                <strong>Infants:</strong> {searchCriteria.infants}
              </p>
            </div>
          )}
        </div>
        <div className={styles.divider}></div>
        <div
          className={`${styles.section} ${styles.date}`}
          onClick={handleSearchClick}
        >
          <FontAwesomeIcon icon={faCalendar} />
          <span className={styles["date-desktop"]}>
            {searchCriteria.dates[0]?.label || "Select departure date"}
            {searchCriteria.dates[1]?.label &&
              ` – ${searchCriteria.dates[1].label}`}
          </span>
          <span className={styles["date-mobile"]}>
            {searchCriteria.dates[0]?.label || "Select departure date"}
          </span>
        </div>
        <div className={styles.divider}></div>
        <div
          className={`${styles.section} ${styles.searchIcon}`}
          onClick={handleSearchClick}
        >
          <FontAwesomeIcon icon={faSearch} />
          <span className={styles.modifySearchText}>Modify Search</span>
        </div>
      </div>
      {showModifySearch && (
        <div className={styles.overlay} onClick={handleOverlayClick} />
      )}
      <ModifySearch
        show={showModifySearch}
        onClose={handleClosePanel}
        onUpdate={updateSearchCriteria}
        searchCriteria={searchCriteria}
      />
    </div>
  );
}

export default SearchBar;
