import React from "react";
import styles from "../Header/styles.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../SearchBar/SearchBar";

function Header({ withSearch = false }) {
  const navigate = useNavigate();
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          <div className={styles.backArrow} onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <img
            src="https://finestflights.com/wp-content/uploads/2022/12/finest-logo-white.png"
            alt="Finest Flights"
            className={styles.headerLogo}
            onClick={() => (window.location.href = "https://finestflights.com")}
          />
        </div>
        <div className={styles.headerCenter}>{withSearch && <SearchBar />}</div>
      </div>
    </header>
  );
}

export default Header;