import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import ViewDetail from "../ViewDetails/ViewDetail";
import { getCityName } from "../../utils/getCityName";
import { FlightContext } from "../../context/FlightContext";
import { DateTime } from "luxon";
import { removeParenthesesContent } from "../../utils/removeParenthesis.js";

function FlightDetails() {
  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const [detailsFlight, setDetailsFlight] = useState(null);
  const { selectedDepartureFlight, selectedReturnFlight } =
    useContext(FlightContext);

  console.log({ selectedDepartureFlight, selectedReturnFlight });

  const handleFlightDetailsClick = (flight) => {
    setDetailsFlight(flight);
    setShowFlightDetails(true);
  };

  const handleClosePanel = () => {
    setShowFlightDetails(false);
  };

  if (!selectedDepartureFlight) {
    return <div>No flight details available</div>;
  }

  const from = removeParenthesesContent(
    selectedDepartureFlight.offers[0].segments[0].from,
  );
  const to = removeParenthesesContent(
    selectedDepartureFlight.offers[0].segments[0].to,
  );

  const calculateTotalPrice = () => {
    let totalPrice = 0;

    if (selectedDepartureFlight.selectedOffer) {
      const selectedDeparturePricing =
        selectedDepartureFlight.selectedOffer.pricing.find(
          (price) =>
            price.cabinType ===
            selectedDepartureFlight.selectedOffer.selectedCabinType,
        );
      totalPrice += selectedDeparturePricing.finalPrice.total;
    }

    if (selectedReturnFlight && selectedReturnFlight.selectedOffer) {
      const selectedReturnPricing =
        selectedReturnFlight.selectedOffer.pricing.find(
          (price) =>
            price.cabinType ===
            selectedReturnFlight.selectedOffer.selectedCabinType,
        );
      totalPrice += selectedReturnPricing.finalPrice.total;
    }

    return totalPrice.toFixed(2);
  };

  const currencyCode = selectedDepartureFlight.selectedOffer.pricing.find(
    (price) =>
      price.cabinType ===
      selectedDepartureFlight.selectedOffer.selectedCabinType,
  ).finalPrice.currencyCode;

  return (
    <div className={styles.container}>
      <div className={styles.detailsBox}>
        <h2>Flight Details</h2>
        <h3>{selectedReturnFlight ? "Round trip" : "One-way trip"}</h3>
        <div className={styles.flightInfo}>
          <div>
            <h4>Departure</h4>
            <p>
              {from} to {to}
              <br />
              {DateTime.fromISO(selectedDepartureFlight.departureDate, {
                setZone: true,
              }).toLocaleString(DateTime.DATE_MED)}
            </p>
          </div>
          <div className={styles.viewDetails}>
            <a
              href="#"
              className={styles.link}
              onClick={() => handleFlightDetailsClick(selectedDepartureFlight)}
            >
              Flight Details
            </a>
          </div>
        </div>
        {selectedReturnFlight && (
          <div className={styles.flightInfo}>
            <div>
              <h4>Return</h4>
              <p>
                {to} to {from}
                <br />
                {DateTime.fromISO(selectedReturnFlight.departureDate, {
                  setZone: true,
                }).toLocaleString(DateTime.DATE_MED)}
              </p>
            </div>
            <div className={styles.viewDetails}>
              <a
                href="#"
                className={styles.link}
                onClick={() => handleFlightDetailsClick(selectedReturnFlight)}
              >
                Flight Details
              </a>
            </div>
          </div>
        )}
        <div className={styles.total}>
          <h3>Grand Total</h3>
          <span>
            {currencyCode} {calculateTotalPrice()}
          </span>
        </div>
        <p className={styles.disclaimer}>
          <strong>Note:</strong> This ticket does not allow cancellation or
          changes in the dates of travel. It also does not accumulate miles.
        </p>
      </div>
      {/* Flight Details Panel */}
      {detailsFlight && (
        <ViewDetail
          show={showFlightDetails}
          onClose={handleClosePanel}
          flight={detailsFlight}
        />
      )}
    </div>
  );
}

export default FlightDetails;
