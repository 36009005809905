// src/hooks/useSearchFlights.js
import { useMutation } from 'react-query';
import axios from 'axios';

const searchFlights = async (formData) => {
  const response = await axios.post('https://api.finestflights.com/api/search', formData);
  console.log(response.data)
  return response.data;
};

export const useSearchFlights = () => {
  return useMutation(searchFlights);
};
