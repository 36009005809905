import React, { useContext, useEffect, useRef, useState } from "react";
import { FlightContext } from "../../context/FlightContext";
import styles from "./styles.module.css";
import { DateTime } from "luxon";

function DatePriceComparison() {
  const { selectedDate, setSelectedDate, flights, selectedDepartureFlight } =
    useContext(FlightContext);
  const [data, setData] = useState([]);
  const scrollContainerRef = useRef(null);

  const extractData = () => {
    const datePriceData = [];
    if (flights) {
      flights
        .filter((flight) => {
          const isSelectingReturnFlight = !!selectedDepartureFlight;
          return flight.itinerarySegment === (isSelectingReturnFlight ? 1 : 0);
        })
        .forEach((result) => {
          const date = result.departureDate;
          if (result.offers.length === 0) {
            datePriceData.push({
              date,
              price: "Offers Unavailable",
              hasOffers: false,
            });
            return;
          }
          // Find the cheapest offer
          const cheapestOffer = result.offers.reduce(
            (minOffer, currentOffer) => {
              const currentPrice = currentOffer.pricing[0].finalPrice.total;
              return currentPrice < minOffer.pricing[0].finalPrice.total
                ? currentOffer
                : minOffer;
            },
            result.offers[0],
          );

          const price = cheapestOffer.pricing[0].finalPrice.total;
          const currencyCode = cheapestOffer.pricing[0].finalPrice.currencyCode;
          datePriceData.push({
            date,
            price: `${price.toFixed(2)} ${currencyCode}`,
            hasOffers: true,
          });
        });
    }
    return datePriceData;
  };

  useEffect(() => {
    const extractedData = extractData();
    setData(extractedData);

    // If no date is selected, default to the first available date
    if (!selectedDate && extractedData.length > 0) {
      setSelectedDate(extractedData[0].date);
    }
  }, [flights, selectedDate]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const highlightedElement = scrollContainer?.querySelector(
      `.${styles.highlighted}`,
    );
    if (highlightedElement) {
      const scrollAmount =
        highlightedElement.offsetLeft -
        scrollContainer.clientWidth / 2 +
        highlightedElement.clientWidth / 2;

      // Use smooth scrolling for a better user experience
      scrollContainer.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  }, [selectedDate, data]); // Trigger the scroll effect when data is loaded or selectedDate changes

  if (data.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer} ref={scrollContainerRef}>
        {data.map((item, index) => (
          <div
            key={index}
            className={`${styles.item} ${
              item.date === selectedDate ? styles.highlighted : ""
            } ${!item.hasOffers ? styles.disabled : ""}`}
            onClick={() => item.hasOffers && handleDateClick(item.date)}
          >
            <div className={styles.date}>
              {DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED)}
            </div>
            <div className={styles.price}>{item.price}</div>
            {item.date === selectedDate && (
              <div className={styles.highlight}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DatePriceComparison;
