import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.css";
import countries from "../../utils/countries.json";
import * as yup from "yup";
import { FlightContext } from "../../context/FlightContext";

// Define age ranges in milliseconds for validation
const ONE_YEAR = 1000 * 60 * 60 * 24 * 365; // One year in milliseconds
const INFANT_AGE = 2 * ONE_YEAR; // Up to 24 months
const CHILD_AGE = 11 * ONE_YEAR; // Up to 11 years
const ADULT_AGE = 12 * ONE_YEAR; // 12 years and older

// Custom validation function to check age range
const checkAgeRange = (date, type) => {
  const currentDate = new Date();
  const birthDate = new Date(date);
  const ageInMilliseconds = currentDate - birthDate;

  switch (type) {
    case "Infant":
      return ageInMilliseconds <= INFANT_AGE;
    case "Child":
      return ageInMilliseconds <= CHILD_AGE;
    case "Adult":
      return ageInMilliseconds >= ADULT_AGE;
    default:
      return false;
  }
};

const getMonthsDifference = (date1, date2) => {
  const year1 = date1.getFullYear();
  const year2 = date2.getFullYear();
  const month1 = date1.getMonth();
  const month2 = date2.getMonth();
  return (year2 - year1) * 12 + (month2 - month1);
};

// Helper function to display passport expiry warnings
const checkPassportExpiry = (expiryDate) => {
  const currentDate = new Date();
  const expiry = new Date(expiryDate);
  const monthsDifference = getMonthsDifference(currentDate, expiry);

  if (expiry < currentDate) {
    return "Passport is expired.";
  } else if (monthsDifference <= 3) {
    return "Passport is 3 months away from expiry.";
  } else if (monthsDifference <= 6) {
    return "Passport is 6 months away from expiry.";
  } else {
    return null; // No warning needed
  }
};

const getValidationSchema = (type) => {
  const baseSchema = {
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    gender: yup.string().required("Gender is required"),
    dob: yup
      .date()
      .required("Date of birth is required")
      .test("is-valid-age", `Invalid age for ${type.toLowerCase()}`, (value) =>
        checkAgeRange(value, type.split(" ")[0]),
      ),
    nationality: yup.string().required("Nationality is required"),
    passportNumber: yup.string().required("Passport number is required"),
    passportExpiry: yup
      .date()
      .required("Passport expiry date is required")
      .test(
        "is-future-date",
        "Passport is expired",
        (value) => new Date(value) > new Date(),
      ),
  };

  if (type.startsWith("Adult")) {
    baseSchema.email = yup
      .string()
      .email("Invalid email address")
      .required("Email is required");
    baseSchema.countryCode = yup.string().required("Country code is required");
    baseSchema.phoneNumber = yup
      .string()
      .matches(/^\d+$/, "Phone number must be digits only")
      .required("Phone number is required");
  } else {
    baseSchema.email = yup
      .string()
      .email("Invalid email address")
      .notRequired();
    baseSchema.countryCode = yup.string().notRequired();
    baseSchema.phoneNumber = yup.string().notRequired();
  }

  return yup.object().shape(baseSchema);
};

function PassengerInfo({ onCompletion, setFirstAdultContact }) {
  const { searchCriteria, passengerDetails, setPassengerDetails } = useContext(FlightContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [errors, setErrors] = useState({});

  console.log({ searchCriteria });

  useEffect(() => {
    const newPassengerInfo = {};
    for (let i = 1; i <= searchCriteria.adults; i++) {
      let key = `Adult ${i}`;
      if (key in passengerDetails) {
        newPassengerInfo[key] = passengerDetails[key];
      }
      newPassengerInfo[key] = {
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        nationality: "",
        passportNumber: "",
        passportExpiry: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      };
    }
    for (let i = 1; i <= searchCriteria.children; i++) {
      let key = `Child ${i}`;
      if (key in passengerDetails) {
        newPassengerInfo[key] = passengerDetails[key];
      }
      newPassengerInfo[key] = {
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        nationality: "",
        passportNumber: "",
        passportExpiry: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      };
    }
    for (let i = 1; i <= searchCriteria.infants; i++) {
      let key = `Infant ${i}`;
      if (key in passengerDetails) {
        newPassengerInfo[key] = passengerDetails[key];
      }
      newPassengerInfo[key] = {
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        nationality: "",
        passportNumber: "",
        passportExpiry: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      };
    }
    
    setPassengerDetails(newPassengerInfo);
  }, [setPassengerDetails]);

  useEffect(() => {
    const allComplete = Object.values(passengerDetails).every((info) => {
      const type = info.dob
        ? checkAgeRange(info.dob, "Adult")
          ? "Adult"
          : checkAgeRange(info.dob, "Child")
            ? "Child"
            : "Infant"
        : "";
      if (type === "Adult") {
        return (
          info.firstName &&
          info.lastName &&
          info.gender &&
          info.dob &&
          info.nationality &&
          info.passportNumber &&
          info.passportExpiry &&
          info.email &&
          info.countryCode &&
          info.phoneNumber
        );
      }
      return (
        info.firstName &&
        info.lastName &&
        info.gender &&
        info.dob &&
        info.nationality &&
        info.passportNumber &&
        info.passportExpiry
      );
    });
    onCompletion(allComplete);
    localStorage.setItem("passengerInfo", JSON.stringify(passengerDetails));

    // Extract the first adult's contact information
    const firstAdult = Object.values(passengerDetails).find((info) =>
      checkAgeRange(info.dob, "Adult"),
    );
    if (firstAdult) {
      setFirstAdultContact({
        email: firstAdult.email,
        countryCode: firstAdult.countryCode,
        phoneNumber: firstAdult.phoneNumber,
      });
    }
  }, [passengerDetails, setPassengerDetails, setFirstAdultContact]);

  const openModal = (passenger) => {
    setSelectedPassenger(passenger);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassengerDetails((prevInfo) => ({
      ...prevInfo,
      [selectedPassenger]: { ...prevInfo[selectedPassenger], [name]: value },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const type = selectedPassenger.split(" ")[0];
    const schema = getValidationSchema(type);

    try {
      await schema.validate(passengerDetails[selectedPassenger], {
        abortEarly: false,
      });
      const passportExpiryWarning = checkPassportExpiry(
          passengerDetails[selectedPassenger].passportExpiry,
      );

      if (passportExpiryWarning) {
        alert(passportExpiryWarning);
      }

      setErrors({});
      closeModal();
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const isAdult = selectedPassenger && selectedPassenger.startsWith("Adult");
  const maxDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
  // console.log(Object.keys(passengerDetails))
  return (
    <div className={styles.container}>
      <h2>Please fill in passenger(s) information</h2>
      <div className={styles.boxContainer}>
        {Object.keys(passengerDetails).map((passenger, index, array) => (
          <div key={passenger}>
            <div className={styles.box} onClick={() => openModal(passenger)}>
              <div>
                <span>{passengerDetails[passenger].firstName || passenger}</span>
                <p>Add the passenger details</p>
              </div>
              <div className={styles.arrow}>&gt;</div>
            </div>
            {index < array.length - 1 && <div className={styles.line}></div>}
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div
          className={`${styles.modal} ${isModalOpen ? styles.modalOpen : ""}`}
          onClick={handleOverlayClick}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <h2>
                {passengerDetails[selectedPassenger].firstName}{" "}
                {passengerDetails[selectedPassenger].lastName}
              </h2>
              <button onClick={closeModal} className={styles.closeButton}>
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <form onSubmit={handleSubmit}>
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={passengerDetails[selectedPassenger].firstName}
                  onChange={handleInputChange}
                  required
                />
                {errors.firstName && (
                  <p className={styles.errorText}>{errors.firstName}</p>
                )}
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={passengerDetails[selectedPassenger].lastName}
                  onChange={handleInputChange}
                  required
                />
                {errors.lastName && (
                  <p className={styles.errorText}>{errors.lastName}</p>
                )}
                <label>Gender</label>
                <div className={styles.radioGroup}>
                  <div className={styles.radioButton}>
                    <label htmlFor="female">Female</label>
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      checked={
                          passengerDetails[selectedPassenger].gender === "female"
                      }
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className={styles.radioButton}>
                    <label htmlFor="male">Male</label>
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      checked={
                          passengerDetails[selectedPassenger].gender === "male"
                      }
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                {errors.gender && (
                  <p className={styles.errorText}>{errors.gender}</p>
                )}
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={passengerDetails[selectedPassenger].dob}
                  onChange={handleInputChange}
                  required
                  max={maxDate}
                />
                {errors.dob && <p className={styles.errorText}>{errors.dob}</p>}
                <label>Nationality</label>
                <select
                  name="nationality"
                  value={passengerDetails[selectedPassenger].nationality}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select your nationality (same as passport)
                  </option>
                  {countries.map((country) => (
                    <option
                      key={country.alpha_2_code}
                      value={country.nationality}
                    >
                      {country.nationality}
                    </option>
                  ))}
                </select>
                {errors.nationality && (
                  <p className={styles.errorText}>{errors.nationality}</p>
                )}
                <label>Passport Number</label>
                <input
                  type="text"
                  name="passportNumber"
                  value={passengerDetails[selectedPassenger].passportNumber}
                  onChange={handleInputChange}
                  required
                />
                {errors.passportNumber && (
                  <p className={styles.errorText}>{errors.passportNumber}</p>
                )}
                <label>Passport Expiry Date</label>
                <input
                  type="date"
                  name="passportExpiry"
                  value={passengerDetails[selectedPassenger].passportExpiry}
                  onChange={handleInputChange}
                  required
                />
                {errors.passportExpiry && (
                  <p className={styles.errorText}>{errors.passportExpiry}</p>
                )}
                <label>Email {isAdult ? "(required)" : "(optional)"}</label>
                <input
                  type="email"
                  name="email"
                  value={passengerDetails[selectedPassenger].email}
                  onChange={handleInputChange}
                  required={isAdult}
                  className={
                    isAdult ? styles.requiredField : styles.optionalField
                  }
                />
                {errors.email && (
                  <p className={styles.errorText}>{errors.email}</p>
                )}
                <div className={styles.phoneNumber}>
                  <label>
                    Country Code {isAdult ? "(required)" : "(optional)"}
                  </label>
                  <select
                    name="countryCode"
                    value={passengerDetails[selectedPassenger].countryCode}
                    onChange={handleInputChange}
                    required={isAdult}
                    className={
                      isAdult ? styles.requiredField : styles.optionalField
                    }
                  >
                    <option value="" disabled>
                      Select your country code
                    </option>
                    {countries.map((country) => (
                      <option
                        key={country.alpha_2_code}
                        value={country.phone_code}
                      >
                        {country.country} {country.phone_code}
                      </option>
                    ))}
                  </select>
                  {errors.countryCode && (
                    <p className={styles.errorText}>{errors.countryCode}</p>
                  )}
                  <label>
                    Phone Number {isAdult ? "(required)" : "(optional)"}
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={passengerDetails[selectedPassenger].phoneNumber}
                    onChange={handleInputChange}
                    required={isAdult}
                    pattern="\d*"
                    className={
                      isAdult ? styles.requiredField : styles.optionalField
                    }
                  />
                  {errors.phoneNumber && (
                    <p className={styles.errorText}>{errors.phoneNumber}</p>
                  )}
                </div>
                <p>
                  Attention: Please double-check all details before proceeding.
                  Once the ticket is issued, changes cannot be made. An email
                  and WhatsApp confirmation will be sent to the provided contact
                  details.
                </p>
                <button type="submit">Save and continue</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PassengerInfo;
