// src/context/FlightContext.jsx
import React, {createContext, useEffect, useState} from "react";
import axios from "axios";
import {convertListFormat} from "../Pages/Checkout/Checkout.jsx";

export const FlightContext = createContext();

export const FlightProvider = ({ children }) => {
  const [flights, setFlights] = useState([]);
  const [returnFlights, setReturnFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [lastSearchId, setLastSearchId] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({
    origin: "",
    originCity: "",
    destination: "",
    destinationCity: "",
    passengers: 0,
    adults: 0,
    children: 0,
    infants: 0,
    dates: [
      { date: "", label: "" },
      { date: "", label: "" },
    ],
    tripType: "", // Added tripType here
  });
  const [selectedDepartureFlight, setSelectedDepartureFlight] = useState(null);
  const [selectedReturnFlight, setSelectedReturnFlight] = useState(null);
  const [passengerDetails, setPassengerDetails] = useState(() => {
      const storedPassengerInfo = localStorage.getItem("passengerInfo");
      return storedPassengerInfo ? JSON.parse(storedPassengerInfo) : {};
  });
  const [contactDetails, setContactDetails] = useState({});
  const clearSearchCriteria = () => {
    setSearchCriteria({});
  };
    useEffect(() => {
        if (!selectedDepartureFlight) return;

        // Create a debounce timer
        const debounceTimer = setTimeout(() => {
            // Create an AbortController instance
            const controller = new AbortController();

            const itinerary = [
                {
                    segmentIndex: selectedDepartureFlight.itinerarySegment,
                    offerId: selectedDepartureFlight.selectedOffer.metadata.uuid,
                    cabinType: selectedDepartureFlight.selectedOffer.selectedCabinType,
                },
            ];

            if (searchCriteria.tripType === "return") {
                if (!selectedReturnFlight) return;
                itinerary.push({
                    segmentIndex: selectedReturnFlight.itinerarySegment,
                    offerId: selectedReturnFlight.selectedOffer.metadata.uuid,
                    cabinType: selectedReturnFlight.selectedOffer.selectedCabinType,
                });
            }

            const email = contactDetails.email;
            const countryCode = contactDetails.selectedCountryCode;
            const phoneNumber = contactDetails.mobile;

            if (email && phoneNumber && countryCode) {
                const passengerData = {
                    email,
                    phone_number: countryCode + phoneNumber,
                    passengers: convertListFormat(passengerDetails),
                    searchId: lastSearchId,
                    ts: new Date().getTime(),
                    itinerary: itinerary,
                };

                // Make the POST request with axios, passing the AbortController's signal
                axios
                    .post(
                        "https://n8n.finestflights.com/webhook/f218a3b3-5679-410f-87ed-8081ae786b07",
                        passengerData,
                        {
                            signal: controller.signal,
                        }
                    )
                    .catch((err) => {
                        if (axios.isCancel(err)) {
                            console.log("Request canceled", err.message);
                        } else {
                            console.error("Error sending data", err);
                        }
                    });
            }

            // Cleanup function to cancel the request if effect reenters or unmounts
            return () => {
                controller.abort();
            };
        }, 5000); // 5-second delay

        // Cleanup function to clear the debounce timer if data changes before the 5 seconds are up
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [
        contactDetails.email,
        contactDetails.mobile,
        contactDetails.selectedCountryCode,
        lastSearchId,
        passengerDetails,
        searchCriteria.tripType,
        selectedDepartureFlight,
        selectedReturnFlight,
    ]);
  return (
    <FlightContext.Provider
      value={{
        flights,
        setFlights,
        returnFlights,
        setReturnFlights,
        isLoading,
        setIsLoading,
        selectedDate,
        setSelectedDate,
        isError,
        setIsError,
        searchCriteria,
        setSearchCriteria,
        selectedDepartureFlight,
        setSelectedDepartureFlight,
        selectedReturnFlight,
        setSelectedReturnFlight,
        passengerDetails,
        setPassengerDetails,
        contactDetails,
        setContactDetails,
        lastSearchId,
        setLastSearchId,
        clearSearchCriteria,
      }}
    >
      {children}
    </FlightContext.Provider>
  );
};
