// src/App.js
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Search from "./Pages/Search/Search";
import Checkout from "./Pages/Checkout/Checkout";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";
// import CheckoutStep2 from "./Pages/Checkout/CheckoutStep2";
import ReturnFlights from "./Pages/ReturnFlights/ReturnFlights";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { FlightContext } from "./context/FlightContext.jsx";

function App() {
  const location = useLocation();
  const showSearch = location.pathname !== "/pay";
  const {
    selectedDepartureFlight,
    selectedReturnFlight,
    searchCriteria
  } = useContext(FlightContext);

  return (
    <>
      <Header withSearch={showSearch} />
      <Routes>
        <Route path="/" element={<Search />} />
        {(searchCriteria.tripType === "return" ? selectedDepartureFlight && selectedReturnFlight : selectedDepartureFlight) && <Route path="/checkout" element={<Checkout />} />}
        {selectedDepartureFlight && <Route path="/return-flights" element={<ReturnFlights />} />}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;