// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import queryClient from "./react-query/queryClient";
import App from "./App";
import "./index.css";
import { FlightProvider } from "./context/FlightContext";

ReactDOM.createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <FlightProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FlightProvider>
  </QueryClientProvider>,
);
