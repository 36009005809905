import React, { useContext, useEffect } from "react";
import DatePriceComparison from "../../components/DatePriceComparison/DatePriceComparison";
import FlightList from "../../components/FlightList/FlightList";
// import SearchFilter from "../../components/SearchFilter/SearchFilter";
import styles from "./styles.module.css";
import { FlightContext } from "../../context/FlightContext";
import { getCityName } from "../../utils/getCityName";
import { findMatchingOrClosestOfferDate } from "../../utils/FindMatchingOrClosestOffer.jsx";

function Search() {
  const {
    searchCriteria,
    isLoading,
    isError,
    selectedDepartureFlight,
    setSelectedDepartureFlight,
    setSelectedReturnFlight,
    selectedDate,
    flights,
    setSelectedDate,
  } = useContext(FlightContext);

  const hasResults = flights && flights.length > 0;
  useEffect(() => {
    setSelectedDepartureFlight(null);
    setSelectedReturnFlight(null);

    if (
      searchCriteria &&
      searchCriteria.dates.length > 0 &&
      searchCriteria.dates[0].date
    ) {
      setSelectedDate(
        findMatchingOrClosestOfferDate(
          searchCriteria.dates[0].date,
          flights.filter((result) => result.itinerarySegment === 0),
        ),
      );
    }
  }, []);

  const isSearchPerformed =
    searchCriteria &&
    searchCriteria.dates.length > 0 &&
    searchCriteria.dates[0].date;
  const isAlternativeDate =
    isSearchPerformed && selectedDate !== searchCriteria.dates[0].date;

  console.log({ searchCriteria });

  return (
    <div className={styles.searchContainer}>
      <DatePriceComparison />

      {/* Banner for alternative date */}
      {isSearchPerformed && isAlternativeDate && !isLoading && !isError && (
        <div className={styles.banner}>
          <p>We were able to find an offer on an alternative date</p>
        </div>
      )}

      {hasResults && !isLoading && !isError && (
        <div className={styles.title}>
          <p>
            Select your{" "}
            <span className={`${styles.highlight} ${styles.bold}`}>
              {selectedDepartureFlight ? "return" : "departure"}
            </span>{" "}
            flight from{" "}
            <span className={styles.highlight}>
              {searchCriteria.originCity}
            </span>{" "}
            to{" "}
            <span className={styles.highlight}>
              {searchCriteria.destinationCity}
            </span>
          </p>
        </div>
      )}

      <div className={styles.searchBody}>
        <div className={styles.flightListContainer}>
          <FlightList
            isLoading={isLoading}
            isError={isError}
            isReturn={!!selectedDepartureFlight}
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
}

export default Search;
