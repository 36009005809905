import React, { useContext } from "react";
import { FlightContext } from "../../context/FlightContext";
import FlightItem from "../FlightItem/FlightItem";
import LoadingSpinner from "../LoadingSpinner/Spinner";
import styles from "./styles.module.css";
import whatsapp from "../../images/whatsapp.png";

function FlightList({ isReturn = false, isLoading, selectedDate, isError }) {
  const { flights } = useContext(FlightContext);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Ensure flights is initialized and not undefined
  const validOffers = flights?.flatMap((flight) =>
    flight.itinerarySegment === (isReturn ? 1 : 0) &&
    flight.offers.length > 0 &&
    flight.departureDate === selectedDate
      ? flight.offers
      : [],
  );

  const offerCount = validOffers ? validOffers.length : 0;
  const resultsText =
    offerCount === 1
      ? "1 available flight"
      : offerCount > 1
        ? `${offerCount} available flights`
        : "No flights to display. Please search for flights.";

  return (
    <div className={styles.flightListContainer}>
      {isError ? (
        <div className={styles.noFlights}>
          Error loading flights. Please try again.
        </div>
      ) : offerCount > 0 ? (
        <>
          <p className={styles.resultsText}>{resultsText}</p>
          {validOffers.map((offer, index) => (
            <FlightItem
              key={index}
              flight={flights.find((flight) => flight.offers.includes(offer))}
              offer={offer}
              isReturn={isReturn}
            />
          ))}
        </>
      ) : (
        <div className={styles.noFlights}>{resultsText}</div>
      )}

      {/* This message will always be shown */}
      <div className={styles.messageContainer}>
        <p className={styles.message}>
          Didn't find what you want? <span>Talk to our travel experts!</span>
        </p>
        <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/971553115600"
          className={styles.whatsappButton}
          target="_blank"
          rel="noopener noreferrer"
        >
          {/*<img alt="Chat on WhatsApp" src={whatsapp} />*/}
          <div className={styles.button}>
            <img
              src={whatsapp}
              alt="Chat on WhatsApp"
              className={styles.whatsappLogo}
            />
            <p>Chat on Whatsapp</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default FlightList;
