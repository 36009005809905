import React, {useContext, useEffect} from "react";
import FlightList from "../../components/FlightList/FlightList";
import styles from "./styles.module.css";
import { FlightContext } from "../../context/FlightContext";
import { getCityName } from "../../utils/getCityName";
import DatePriceComparison from "../../components/DatePriceComparison/DatePriceComparison";
import {findMatchingOrClosestOfferDate} from "../../utils/FindMatchingOrClosestOffer.jsx";
// import SearchFilter from "../../components/SearchFilter/SearchFilter";

function ReturnFlights() {
  const { isLoading, isError, searchCriteria, selectedDate, setSelectedReturnFlight, flights, setSelectedDate } =
    useContext(FlightContext);

    useEffect(() => {
        setSelectedReturnFlight(null);

        if (searchCriteria && searchCriteria.dates.length > 1 && searchCriteria.dates[1].date) {
            const filteredReturnFlights = flights.filter(
                (f) =>
                    f.itinerarySegment === 1 &&
                    new Date(f.departureDate) > new Date(searchCriteria.dates[0].date),
            );

            setSelectedDate(
                findMatchingOrClosestOfferDate(
                    searchCriteria.dates[1].date,
                    filteredReturnFlights,
                ),
            );
        }
    }, []);
    
  return (
    <div className={styles.searchContainer}>
      <DatePriceComparison />
      <div className={styles.title}>
        <p>
          Select your <span className={styles.flightType}>return flight</span>{" "}
          from{" "}
          <span className={styles.highlight}>
            {searchCriteria.destinationCity}
          </span>{" "}
          to{" "}
          <span className={styles.highlight}>{searchCriteria.originCity}</span>
        </p>
      </div>
      <div className={styles.searchBody}>
        {/*<div className={styles.filterContainer}>
          <SearchFilter isReturn={true}/>
        </div>*/}
        <div className={styles.flightListContainer}>
          <FlightList
            isLoading={isLoading}
            isError={isError}
            isReturn
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
}

export default ReturnFlights;
