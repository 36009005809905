import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import PassengerInfo from "../../components/PassengerInfo/PassengerInfo";
import BookingConfirmation from "../../components/BookingConfirmation/BookingConfirmation";
import FlightDetails from "../../components/FlightDetails/FlightDetails";
import "react-toastify/dist/ReactToastify.css";
import { FlightContext } from "../../context/FlightContext";

function Checkout() {
  const [isPassengerInfoComplete, setIsPassengerInfoComplete] = useState(false);
  const [isBookingConfirmationComplete, setIsBookingConfirmationComplete] =
    useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    JSON.parse(localStorage.getItem("isCheckboxChecked")) || false,
  );
  const [firstAdultContact, setFirstAdultContact] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    selectedDepartureFlight,
    selectedReturnFlight,
    passengerDetails,
    contactDetails,
    searchCriteria,
    lastSearchId,
    // setSelectedDepartureFlight,
    // setSelectedReturnFlight,
  } = useContext(FlightContext);

  // Correctly load data from local storage
  useEffect(() => {
    const storedCheckboxChecked = JSON.parse(
      localStorage.getItem("isCheckboxChecked"),
    );

    if (storedCheckboxChecked) setIsCheckboxChecked(storedCheckboxChecked);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "isCheckboxChecked",
      JSON.stringify(isCheckboxChecked),
    );
  }, [isCheckboxChecked]);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handlePassengerInfoCompletion = (isComplete) => {
    setIsPassengerInfoComplete(isComplete);
  };

  const handleBookingConfirmationCompletion = (isComplete) => {
    setIsBookingConfirmationComplete(isComplete);
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    localStorage.setItem(
      "selectedDepartureFlight",
      JSON.stringify(selectedDepartureFlight),
    );
    localStorage.setItem(
      "selectedReturnFlight",
      JSON.stringify(selectedReturnFlight),
    );

    // Cache the first adult contact information
    if (firstAdultContact) {
      localStorage.setItem(
        "firstAdultContact",
        JSON.stringify(firstAdultContact),
      );
    }

    // Collect data to send to the API
    const name = ""; // TODO: FILL IN WITH NAME
    const email = contactDetails.email;
    const countryCode = contactDetails.selectedCountryCode;
    const phoneNumber = contactDetails.mobile;

    const itinerary = [
      {
        segmentIndex: selectedDepartureFlight.itinerarySegment,
        offerId: selectedDepartureFlight.selectedOffer.metadata.uuid,
        cabinType: selectedDepartureFlight.selectedOffer.selectedCabinType,
      },
    ];

    if (searchCriteria.tripType === "return") {
      itinerary.push({
        segmentIndex: selectedReturnFlight.itinerarySegment,
        offerId: selectedReturnFlight.selectedOffer.metadata.uuid,
        cabinType: selectedReturnFlight.selectedOffer.selectedCabinType,
      });
    }

    const paymentLinkData = {
      passengers: convertListFormat(passengerDetails),
      searchId: lastSearchId,
      itinerary: itinerary,
      contactInformation: {
        name,
        email,
        countryCode,
        phoneNumber,
      },
    };

    try {
      const response = await postData(
        "https://api.finestflights.com/api/payment",
        paymentLinkData,
      );
      console.log(response);
      window.location.assign(response.url);
    } catch (error) {
      console.error("Error creating payment link:", error);
      alert("Failed to create payment link. Please try again.");
    }
  };

  const isFormValid =
    isPassengerInfoComplete &&
    isBookingConfirmationComplete &&
    isCheckboxChecked;

  const handleMouseEnter = () => {
    if (!isFormValid) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <PassengerInfo
          onCompletion={handlePassengerInfoCompletion}
          setFirstAdultContact={setFirstAdultContact}
        />
        {isPassengerInfoComplete && (
          <BookingConfirmation
            onCompletion={handleBookingConfirmationCompletion}
            firstAdultContact={firstAdultContact}
          />
        )}
      </div>
      <div className={styles.rightSection}>
        <FlightDetails
          flight={selectedDepartureFlight || selectedReturnFlight}
        />
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="agree"
            className={styles.checkbox}
            onChange={handleCheckboxChange}
            checked={isCheckboxChecked}
          />
          <label htmlFor="agree" className={styles.checkboxLabel}>
            By checking this box, I acknowledge that I have read, understood,
            and agreed to the{" "}
            <a
              href="https://finestflights.com/terms-conditions/"
              target="_blank"
              className={styles.link}
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://finestflights.com/privacy-policy/"
              target="_blank"
              className={styles.link}
            >
              Privacy Policy
            </a>{" "}
            of Finest Flights.
          </label>
        </div>
        <div className={styles.buttonGrp}>
          {/*<button className={styles.cancelButton}>Cancel</button>*/}
          <div
            className={styles.tooltipContainer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className={styles.reviewButton}
              disabled={!isFormValid}
              onClick={handleFormSubmission}
            >
              Purchase
            </button>
            {showTooltip && (
              <div className={styles.tooltip}>
                Fill in the passenger information to proceed
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function convertListFormat(data) {
  const getType = (key) => {
    if (key.startsWith("Adult")) return "adult";
    if (key.startsWith("Child")) return "child";
    if (key.startsWith("Infant")) return "infant";
    return "unknown";
  };

  return Object.keys(data).map((key) => {
    const item = data[key];
    return {
      type: getType(key),
      firstName: item.firstName,
      lastName: item.lastName,
      gender: item.gender,
      dateOfBirth: item.dob,
      nationality: item.nationality,
      passportNumber: item.passportNumber,
      passportExpiry: item.passportExpiry,
      email: item.email || "",
      countryCode: item.countryCode || "",
      phoneNumber: item.phoneNumber || "",
    };
  });
}

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok: " + response.statusText);
  }
  return response.json();
}

export default Checkout;
