import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import countries from "../../utils/countries.json";
import * as yup from "yup";
import { FlightContext } from "../../context/FlightContext";

const schema = yup.object().shape({
  email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  countryCode: yup.string().required("Country code is required"),
  mobile: yup.string().required("Mobile number is required"),
});

function BookingConfirmation({ onCompletion, firstAdultContact }) {
  const { setContactDetails } = useContext(FlightContext);
  const [email, setEmail] = useState(firstAdultContact.email || "");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
      firstAdultContact.countryCode || ""
  );
  const [mobile, setMobile] = useState(firstAdultContact.phoneNumber || "");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEmail(firstAdultContact.email || "");
    setSelectedCountryCode(firstAdultContact.countryCode || "");
    setMobile(firstAdultContact.phoneNumber || "");
  }, [firstAdultContact]);

  useEffect(() => {
    localStorage.setItem("email", email);
    localStorage.setItem("selectedCountryCode", selectedCountryCode);
    localStorage.setItem("mobile", mobile);
    validate();
  }, [email, selectedCountryCode, mobile]);

  const validate = async () => {
    try {
      await schema.validate(
          { email, countryCode: selectedCountryCode, mobile },
          { abortEarly: false }
      );
      onCompletion(true);
      setContactDetails({ email, selectedCountryCode, mobile });
      setErrors({});
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
      onCompletion(false);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleBlur = () => {
    validate();
  };

  return (
      <div className={styles.container}>
        <h2>Tell us where we should send your booking confirmation to.</h2>
        <div className={styles.formBox}>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email Address</label>
            <input
                type="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleBlur}
                required
            />
            {errors.email && <p className={styles.errorText}>{errors.email}</p>}
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.formGroup}>
              <label htmlFor="country">Country/Region code</label>
              <select
                  id="country"
                  value={selectedCountryCode}
                  onChange={handleCountryChange}
                  onBlur={handleBlur}
                  required
              >
                <option value="" disabled>
                  Select
                </option>
                {countries.map((country) => (
                    <option key={country.alpha_2_code} value={country.phone_code}>
                      {country.country} {country.phone_code}
                    </option>
                ))}
              </select>
              {errors.countryCode && (
                  <p className={styles.errorText}>{errors.countryCode}</p>
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="mobile">Mobile Number</label>
              <input
                  type="tel"
                  id="mobile"
                  value={mobile}
                  onChange={handleMobileChange}
                  onBlur={handleBlur}
                  required
              />
              {errors.mobile && (
                  <p className={styles.errorText}>{errors.mobile}</p>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default BookingConfirmation;
