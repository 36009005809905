import React from "react";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faLock,
  faSuitcaseRolling,
  faSuitcase,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

function ExpandedClass({
  className,
  originalPrice,
  discountedPrice,
  onSelectFlight,
  isReturn,
  baggageAllowances,
  seatsRemaining,
}) {
  const baggageInfo = baggageAllowances[className] || {
    checkedBags: { pieces: null, weightAmount: 0, weightType: "", notes: "" },
    handBaggage: { pieces: null, weightAmount: 0, weightType: "", notes: "" },
  };
  const formatSeatsRemaining = () => {
    if (seatsRemaining === null || seatsRemaining === undefined) {
      return null;
    }
    if (seatsRemaining === 1) {
      return "Only 1 seat remaining";
    }
    if (seatsRemaining <= 3) {
      return `Only ${seatsRemaining} seats remaining`;
    }
    return `${seatsRemaining} seats remaining`;
  };

  return (
    <div className={styles.container}>
      <h3>{className}</h3>
      <div className={styles.expandedClassContainer}>
        <div className={styles.details}>
          <p>
            <FontAwesomeIcon icon={faBan} aria-label="non-refundable" />{" "}
            Non-Refundable
          </p>
          <p>
            <FontAwesomeIcon icon={faLock} aria-label="non-flexible" />{" "}
            Non-Flexible
          </p>
          <p className={styles.baggage}>
            <FontAwesomeIcon
              icon={faSuitcaseRolling}
              aria-label="checked baggage"
            />{" "}
            Checked Baggage:{" "}
            {baggageInfo.checkedBags.pieces !== null &&
              `${baggageInfo.checkedBags.pieces} piece(s),`}{" "}
            {baggageInfo.checkedBags.weightAmount}kg
            {baggageInfo.checkedBags.notes && (
              <span className={styles.tooltip}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  aria-label="checked baggage info"
                  className={styles.infoIcon}
                />
                <span className={styles.tooltipText}>
                  {baggageInfo.checkedBags.notes}
                </span>
              </span>
            )}
          </p>
          <p className={styles.baggage}>
            <FontAwesomeIcon icon={faSuitcase} aria-label="hand baggage" /> Hand
            Baggage:{" "}
            {baggageInfo.handBaggage.pieces !== null &&
              `${baggageInfo.handBaggage.pieces} piece(s),`}{" "}
            {baggageInfo.handBaggage.weightAmount}kg
            {baggageInfo.handBaggage.notes && (
              <span className={styles.tooltip}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  aria-label="hand baggage info"
                  className={styles.infoIcon}
                />
                <span className={styles.tooltipText}>
                  {baggageInfo.handBaggage.notes}
                </span>
              </span>
            )}
          </p>
        </div>
        <div className={styles.priceDetails}>
          <span className={styles.totalPassengers}>
            Total for all passengers
          </span>
          <span className={styles.originalPrice}>{originalPrice} QAR</span>
          <span className={styles.discountedPrice}>{discountedPrice} QAR</span>
          {formatSeatsRemaining() && (
            <p className={styles.seats}>{formatSeatsRemaining()}</p>
          )}
        </div>
      </div>
      <button onClick={onSelectFlight} className={styles.selectButton}>
        {isReturn ? "Select Return Flight" : "Select Departure Flight"}
      </button>
    </div>
  );
}

export default ExpandedClass;
