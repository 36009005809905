import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";
import PassengersClassPanel from "../PassengersClassPanel/PassengersClassPanel";
import SwitchButton from "../SwitchButton/SwitchButton.jsx";

function ModifySearch({ onClose, show, onUpdate, searchCriteria }) {
  const localStorageKey = "lastSearchCriteria";

  // Retrieve cached search criteria from localStorage if available
  const cachedSearchCriteria =
    JSON.parse(localStorage.getItem(localStorageKey)) || searchCriteria;

  const [tripType, setTripType] = useState(
    cachedSearchCriteria.tripType || "return",
  );
  const [showPassengersPanel, setShowPassengersPanel] = useState(true);
  const [adults, setAdults] = useState(cachedSearchCriteria.adults || 1);
  const [children, setChildren] = useState(cachedSearchCriteria.children || 0);
  const [infants, setInfants] = useState(cachedSearchCriteria.infants || 0);
  const [travelClass, setTravelClass] = useState(
    cachedSearchCriteria.travelClass || "Economy",
  );
  const [origin, setOrigin] = useState(cachedSearchCriteria.origin || "");
  const [destination, setDestination] = useState(
    cachedSearchCriteria.destination || "",
  );
  const [departureDate, setDepartureDate] = useState(
    cachedSearchCriteria.dates[0]?.date
      ? new Date(cachedSearchCriteria.dates[0]?.date)
      : null,
  );
  const [returnDate, setReturnDate] = useState(
    cachedSearchCriteria.dates[1]?.date
      ? new Date(cachedSearchCriteria.dates[1]?.date)
      : null,
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [originSearch, setOriginSearch] = useState("");
  const [destinationSearch, setDestinationSearch] = useState("");
  const [originAirports, setOriginAirports] = useState([]);
  const [destinationAirports, setDestinationAirports] = useState([]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"; // Disable body scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable body scrolling
    }
    return () => {
      document.body.style.overflow = "auto"; // Clean up on component unmount
    };
  }, [show]);

  useEffect(() => {
    if (tripType === "oneway") {
      setReturnDate(null);
    }
  }, [tripType]);

  useEffect(() => {
    const isValid =
      origin &&
      destination &&
      departureDate &&
      (!returnDate ||
        tripType === "oneway" ||
        (tripType === "return" && returnDate));
    setIsFormValid(isValid);
  }, [origin, destination, departureDate, returnDate, tripType]);

  useEffect(() => {
    // Fetch options based on cached values when the component mounts
    if (origin && !originAirports.length) {
      fetch(`https://api.finestflights.com/api/airports?query=${origin}`)
        .then((response) => response.json())
        .then((data) => setOriginAirports(data));
    }
    if (destination && !destinationAirports.length) {
      fetch(`https://api.finestflights.com/api/airports?query=${destination}`)
        .then((response) => response.json())
        .then((data) => setDestinationAirports(data));
    }
  }, [origin, destination]);

  useEffect(() => {
    // Fetch options when user types in the search input
    if (originSearch) {
      fetch(`https://api.finestflights.com/api/airports?query=${originSearch}`)
        .then((response) => response.json())
        .then((data) => setOriginAirports(data));
    }
  }, [originSearch]);

  useEffect(() => {
    if (destinationSearch) {
      fetch(
        `https://api.finestflights.com/api/airports?query=${destinationSearch}`,
      )
        .then((response) => response.json())
        .then((data) => setDestinationAirports(data));
    }
  }, [destinationSearch]);

  const handleTripTypeChange = (tripType) => {
    setTripType(tripType);
    if (tripType === "oneway") {
      setReturnDate(null);
    }
  };

  const togglePassengersPanel = () => {
    setShowPassengersPanel(!showPassengersPanel);
  };

  const handlePassengersChange = (newAdults, newChildren, newInfants) => {
    setAdults(newAdults);
    setChildren(newChildren);
    setInfants(newInfants);
  };

  const handleTravelClassChange = (newTravelClass) => {
    setTravelClass(newTravelClass);
  };

  const handleDepartureDateChange = (date) => {
    if (date > maxDate) {
      alert("Please select a date within 330 days from today.");
      setDepartureDate(null);
    } else {
      setDepartureDate(date);
    }
  };

  const handleReturnDateChange = (date) => {
    if (date > maxDate) {
      alert("Please select a date within 330 days from today.");
      setReturnDate(null);
    } else {
      setReturnDate(date);
    }
  };

  const formatDateLabel = (date) => {
    if (!date) return "";
    const options = { weekday: "short", day: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleSearchFlights = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    if (departureDate < new Date(currentDate)) {
      alert("Please select a future date for departure.");
      return;
    }

    if (tripType === "return" && returnDate < departureDate) {
      alert("Please select a date on or after the departure date for return.");
      return;
    }

    const originAirport = originAirportOptions.find(
      (option) => option.value === origin,
    );

    const destinationAirport = destinationAirportOptions.find(
      (option) => option.value === destination,
    );

    function formatDateToString(date) {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if necessary
      const day = ("0" + date.getDate()).slice(-2); // Add leading zero if necessary
      return year + "-" + month + "-" + day;
    }
    const newSearchCriteria = {
      origin,
      originCity: originAirport?.label.split(",")[0], // Extract city name
      destination,
      destinationCity: destinationAirport?.label.split(",")[0], // Extract city name
      passengers: adults + children + infants,
      adults,
      children,
      infants,
      dates: [
        {
          date: formatDateToString(departureDate),
          label: formatDateLabel(departureDate),
        },
        tripType === "return" && {
          date: returnDate && formatDateToString(returnDate),
          label: formatDateLabel(returnDate),
        },
      ].filter(Boolean),
      tripType,
      travelClass,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(newSearchCriteria));
    onUpdate(newSearchCriteria);
    onClose();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && isFormValid) {
        handleSearchFlights();
      }
    };
    if (show) {
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [show, isFormValid]);

  const originAirportOptions = originAirports.map((airport) => ({
    value: airport.iata,
    label: `${airport.city}, ${airport.country} (${airport.iata}) - ${airport.airport}`,
  }));

  const destinationAirportOptions = destinationAirports.map((airport) => ({
    value: airport.iata,
    label: `${airport.city}, ${airport.country} (${airport.iata}) - ${airport.airport}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f47c23"
        : state.isFocused
          ? "#fde1cd"
          : null,
      color: state.isSelected ? "white" : "black",
    }),
  };

  const maxSelectableDate = new Date();
  maxSelectableDate.setDate(maxSelectableDate.getDate() + 330);
  const maxDate = maxSelectableDate;

  return (
    <div className={`${styles.panel} ${show ? styles.show : styles.hide}`}>
      <div className={styles.header}>
        <span>Modify Search</span>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
      </div>
      <div className={styles.scrollContainer}>
        <div className={styles.content}>
          <div className={styles.tripType}>
            <SwitchButton tripType={tripType} onChange={handleTripTypeChange} />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label}>From</label>
            <Select
              className={styles.select}
              value={originAirportOptions.find(
                (option) => option.value === origin,
              )}
              onInputChange={(inputValue) => setOriginSearch(inputValue)}
              onChange={(selectedOption) => setOrigin(selectedOption.value)}
              options={originAirportOptions}
              isSearchable
              placeholder="Search for origin"
              styles={customStyles}
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label}>To</label>
            <Select
              className={styles.select}
              value={destinationAirportOptions.find(
                (option) => option.value === destination,
              )}
              onInputChange={(inputValue) => setDestinationSearch(inputValue)}
              onChange={(selectedOption) =>
                setDestination(selectedOption.value)
              }
              options={destinationAirportOptions}
              isSearchable
              placeholder="Search for destination"
              styles={customStyles}
            />
          </div>

          <div className={styles.dateGroup}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Departure</label>
              <DatePicker
                selected={departureDate}
                onChange={handleDepartureDateChange}
                minDate={new Date()}
                maxDate={maxDate}
                dateFormat="dd/MM/yyyy"
                className={styles.datePicker}
                popperPlacement="bottom-end"
                onFocus={(e) => e.target.blur()}
                onClick={(e) => e.target.blur()}
              />
            </div>
            {tripType === "return" && (
              <div className={styles.inputGroup}>
                <label className={styles.label}>Return</label>
                <DatePicker
                  selected={returnDate}
                  onChange={handleReturnDateChange}
                  minDate={departureDate || new Date()}
                  maxDate={maxDate}
                  dateFormat="dd/MM/yyyy"
                  className={styles.datePicker}
                  popperPlacement="bottom-end"
                  onClick={(e) => e.target.blur()}
                  onFocus={(e) => e.target.blur()}
                />
              </div>
            )}
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Passengers / Class</label>
            <button className={styles.input} onClick={togglePassengersPanel}>
              {adults} Adult{adults > 1 ? "s" : ""}, {children} Child
              {children !== 1 ? "ren" : ""}, {infants} Infant
              {infants > 1 ? "s" : ""} - {travelClass}
            </button>
          </div>
          <PassengersClassPanel
            adults={adults}
            children={children}
            infants={infants}
            travelClass={travelClass}
            onPassengersChange={handlePassengersChange}
            onTravelClassChange={handleTravelClassChange}
            show={showPassengersPanel}
            onClose={togglePassengersPanel}
          />
          <div className={styles.searchButtonContainer}>
            <button
              className={styles.searchButton}
              onClick={handleSearchFlights}
              disabled={!isFormValid}
            >
              Search flights
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifySearch;
