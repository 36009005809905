import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import ExpandedClass from "../ExpandedClass/ExpandedClass";
import ViewDetail from "../ViewDetails/ViewDetail";
import { FlightContext } from "../../context/FlightContext";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { findMatchingOrClosestOfferDate } from "../../utils/FindMatchingOrClosestOffer.jsx";
import { removeParenthesesContent } from "../../utils/removeParenthesis.js";

function FlightItem({ flight, offer, isReturn }) {
  const [expandedClass, setExpandedClass] = useState(null);
  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const [detailsOffer, setDetailsOffer] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const {
    setSelectedDepartureFlight,
    setSelectedReturnFlight,
    flights,
    setReturnFlights,
    setSelectedDate,
    searchCriteria,
    selectedDate,
  } = useContext(FlightContext);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const pricesRef = useRef(null);

  useEffect(() => {
    setExpandedClass(null);
  }, [selectedDate]);

  useEffect(() => {
    if (pricesRef.current) {
      pricesRef.current.scrollLeft = 0;
      pricesRef.current.scrollTo({
        left: 200,
        behavior: "smooth",
      });
    }
  }, [offer]);

  const handleClassClick = (offer, cabinType) => {
    const updatedOffer = { ...offer, selectedCabinType: cabinType };
    setSelectedOffer(updatedOffer);
    setExpandedClass(expandedClass === cabinType ? null : cabinType);
    // Scroll to the element referenced by scrollRef
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleFlightDetailsClick = (e) => {
    e.preventDefault();
    setDetailsOffer(offer);
    setShowFlightDetails(!showFlightDetails);
  };

  const handleClosePanel = () => {
    setShowFlightDetails(false);
  };

  const handleSelectFlight = () => {
    const selectedFlight = { ...flight, selectedOffer };
    if (isReturn) {
      setSelectedReturnFlight(selectedFlight);
      navigate("/checkout");
    } else {
      setSelectedDepartureFlight(selectedFlight);

      if (searchCriteria.tripType === "oneway") {
        navigate("/checkout");
      } else {
        const filteredReturnFlights = flights.filter(
          (f) =>
            f.itinerarySegment === 1 &&
            new Date(f.departureDate) > new Date(searchCriteria.dates[0].date),
        );
        setSelectedDate(
          findMatchingOrClosestOfferDate(
            searchCriteria.dates[1].date,
            filteredReturnFlights,
          ),
        );
        setReturnFlights(filteredReturnFlights);
        navigate("/return-flights");
      }
    }
  };

  const stopText =
    offer.metadata.amountOfConnections === 0
      ? "Non-Stop"
      : `${offer.metadata.amountOfConnections} Stop${
          offer.metadata.amountOfConnections > 1 ? "s" : ""
        }`;

  if (!flight || !offer) {
    return null;
  }

  const getTotalLayoverTimeAndLastArrival = (segments) => {
    if (segments.length === 0)
      return {
        layoverTime: 0,
        lastArrival: null,
        timeDifference: "",
        tripDuration: "",
      };

    let totalLayoverInMinutes = 0;

    // Calculate layover time between segments
    for (let i = 0; i < segments.length - 1; i++) {
      const arrivalTime = DateTime.fromISO(segments[i].arrivalTime, {
        setZone: true,
      });
      const nextDepartureTime = DateTime.fromISO(
        segments[i + 1].departureTime,
        { setZone: true },
      );
      totalLayoverInMinutes += nextDepartureTime.diff(
        arrivalTime,
        "minutes",
      ).minutes;
    }

    const firstDepartureTime = DateTime.fromISO(segments[0].departureTime, {
      setZone: true,
    });
    const lastArrivalTime = DateTime.fromISO(
      segments[segments.length - 1].arrivalTime,
      { setZone: true },
    );

    const tripDurationInMinutes = lastArrivalTime.diff(
      firstDepartureTime,
      "minutes",
    ).minutes;
    const tripDuration = `${Math.floor(tripDurationInMinutes / 60)}h ${tripDurationInMinutes % 60}m`;

    const isNextDay =
      lastArrivalTime.toISODate() !== firstDepartureTime.toISODate();

    const formattedLastArrival = lastArrivalTime.toLocaleString(
      DateTime.TIME_24_SIMPLE,
    );

    const timeDifference = isNextDay ? " <sup>+1</sup>" : "";

    return {
      layoverTime: totalLayoverInMinutes,
      lastArrival: formattedLastArrival + timeDifference,
      tripDuration,
    };
  };

  const { layoverTime, lastArrival, tripDuration } =
    getTotalLayoverTimeAndLastArrival(offer.segments);

  const lastSegment = offer.segments[offer.segments.length - 1];
  const flightDetails = offer.segments[0]; // Use this if you need other general details

  // Sort cabin types based on availability and user search criteria
  const cabinTypes = ["Economy", "Business"];
  const hasFirstOffer = offer.pricing.some(
    (price) => price.cabinType.toLowerCase() === "first",
  );

  if (hasFirstOffer) {
    cabinTypes.push("First");
  }

  const sortedOffers = cabinTypes.map((cabinType) =>
    offer.pricing.find(
      (price) => price.cabinType.toLowerCase() === cabinType.toLowerCase(),
    ),
  );

  // Function to check for maximum discount
  const getMaxDiscountSticker = (price) => {
    const discountPercent = Math.round(
      ((price.originalPrice.total - price.finalPrice.total) /
        price.originalPrice.total) *
        100,
    );

    if (price.cabinType.toLowerCase() === "economy" && discountPercent === 20) {
      return <div className={styles.starSticker} />;
    } else if (
      ["business", "first"].includes(price.cabinType.toLowerCase()) &&
      discountPercent === 42
    ) {
      return <div className={styles.starSticker} />;
    }

    return null;
  };

  return (
    <div ref={scrollRef}>
      <div
        className={`${styles.flightItemContainer} ${
          expandedClass ? styles.expanded : ""
        }`}
      >
        {flightDetails.qSuiteEquipped && (
          <div className={styles.qsuiteLabel}>Suite</div>
        )}
        <div className={styles.airline}>
          <img
            src={flightDetails.airlineLogo}
            alt="Airline"
            className={styles.airlineLogo}
          />
          <span>{flightDetails.airline}</span>
        </div>

        <div className={styles.flightInfo}>
          <div className={styles.flightTime}>
            <div className={styles.time}>
              <span className={styles.departure}>
                {DateTime.fromISO(offer.segments[0].departureTime, {
                  setZone: true,
                }).toLocaleString(DateTime.TIME_24_SIMPLE)}
              </span>
              <span className={styles.direction}>
                {removeParenthesesContent(offer.segments[0].from)}
              </span>
            </div>
            <div className={styles.flightLine}></div>
            <div className={styles.flightIcon}>
              <FontAwesomeIcon icon={faPlane} className={styles.planeIcon} />
              <img
                src={flightDetails.airlineLogo}
                alt="Airline"
                className={styles.mobileLogo}
              />
            </div>
            <div className={styles.flightLine}></div>
            <div className={styles.time}>
              <span
                className={styles.arrival}
                dangerouslySetInnerHTML={{ __html: lastArrival }}
              />
              <span className={styles.direction}>
                {removeParenthesesContent(lastSegment.to)}
              </span>
            </div>
          </div>
          <div className={styles.flightDetails}>
            <span>
              {stopText},
              <br />
              {layoverTime === 0
                ? `Trip Duration: ${tripDuration}`
                : `Layover Time: ${Math.floor(layoverTime / 60)}h ${layoverTime % 60}m`}
            </span>
            <a className={styles.link} onClick={handleFlightDetailsClick}>
              Flight Details
            </a>
          </div>
        </div>

        <div className={styles.cardLine}></div>

        <div ref={pricesRef} className={styles.prices}>
          {sortedOffers.map((price, priceIndex) =>
            price ? (
              <div
                key={priceIndex}
                className={`${styles.class} ${
                  expandedClass === price.cabinType ? styles.expandedClass : ""
                }`}
                onClick={() => handleClassClick(offer, price.cabinType)}
                style={{ position: "relative" }}
              >
                {getMaxDiscountSticker(price)}
                <p className={styles.cabinType}>{price.cabinType}</p>
                <span className={styles.originalPrice}>
                  {price.originalPrice.total} {price.originalPrice.currencyCode}
                </span>
                <span className={styles.discountedPrice}>
                  {price.finalPrice.total} {price.finalPrice.currencyCode}
                </span>
                <span className={styles.percentOff}>
                  {`${Math.round(
                    ((price.originalPrice.total - price.finalPrice.total) /
                      price.originalPrice.total) *
                      100,
                  )}% OFF`}
                </span>
              </div>
            ) : (
              <div
                key={priceIndex}
                className={`${styles.class} ${styles.disabled}`}
              >
                <p className={styles.cabinType}>{cabinTypes[priceIndex]}</p>
                <span className={styles.noOffer}>
                  Offers Unavailable
                  <br />
                  Try different dates
                </span>
              </div>
            ),
          )}
        </div>
      </div>
      <div
        className={`${styles.expandedClassDetails} ${
          expandedClass ? styles.show : styles.hide
        }`}
      >
        {expandedClass &&
          selectedOffer &&
          selectedOffer.pricing.map((price, priceIndex) =>
            expandedClass === price.cabinType ? (
              <ExpandedClass
                key={priceIndex}
                className={expandedClass}
                originalPrice={price.originalPrice.total}
                discountedPrice={price.finalPrice.total}
                onSelectFlight={handleSelectFlight}
                isReturn={isReturn}
                baggageAllowances={flightDetails.baggageAllowances}
                seatsRemaining={price.seatsRemaining}
              />
            ) : null,
          )}
      </div>
      {showFlightDetails && detailsOffer && (
        <ViewDetail
          show={showFlightDetails}
          onClose={handleClosePanel}
          flight={{ ...flight, selectedOffer: detailsOffer }}
          aircraft={offer.segments
            .map((segment) => segment.aircraft)
            .join(", ")}
        />
      )}
    </div>
  );
}

export default FlightItem;
